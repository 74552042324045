/*
 *  ======================================= 
 *  COLORS | _colors
 *  =======================================  
 */

$black: black;
$black2: #2b2a29;
$white: white;
$grey: #f5f5f5;
$grey2: #7d7d7d;
$grey3: #666666;
$orange: #ec691f;

.color{
	&-black{
		color: $black;
	}
	&-black2{
		color: $black2;
	}
	&-white{
		color: $white;
	}
	&-grey{
		color: $grey;
	}
	&-grey2{
		color: $grey2;
	}
	&-grey3{
		color: $grey3;
	}
	&-orange{
		color: $orange;
	}
}