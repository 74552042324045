/**
 * 
 *  RWD
 *  css/partials/_rwd.scss
 *
 */
@media screen and (min-width: 1200px){
	.col-lg-1-quarter{
		float: left;
		width: 10.41666666666667%;
    position: relative;
    min-height: 1px;
	}
	.col-lg-1-half{
		float: left;
		width: 12.5%;
    position: relative;
    min-height: 1px;
	}
	.col-lg-1-three-fourth{
		float: left;
		width: 14.58333333333333%;
    position: relative;
    min-height: 1px;
	}
	.col-lg-2-half{
		float: left;
		width: 20.83333333333333%;
    position: relative;
    min-height: 1px;
	}
	.col-lg-one-fifth{
		float: left;
		width: 20%;
    position: relative;
    min-height: 1px;
	}
}

@media screen and (min-width: 1470px){
	.container{
		width: 1440px;
	}
	.col-xl-offset-0{
		margin-left: 0;
	}
	.col-xl-offset-1{
		margin-left: 8.33333333%;
	}
	.col-xl-offset-2{
		margin-left: 16.66666667%;
	}
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11 {
    float: left; }

  .col-xl-1 {
    width: 8.33333%; }

  .col-xl-2 {
    width: 16.66667%; }

  .col-xl-3 {
    width: 25%; }

  .col-xl-4 {
    width: 33.33333%; }

  .col-xl-5 {
    width: 41.66667%; }

  .col-xl-6 {
    width: 50%; }

  .col-xl-7 {
    width: 58.33333%; }

  .col-xl-8 {
    width: 66.66667%; }

  .col-xl-9 {
    width: 75%; }

  .col-xl-10 {
    width: 83.33333%; }

  .col-xl-11 {
    width: 91.66667%; }

  .col-xl-12 {
    width: 100%; }
}
@media screen and (max-width: 1469px){
	.header{
		&-menu{
			&-desktop{
				> li{
					margin-left: 20px;
					ul{
						li{
							margin-left: 0;
							a{
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
	
	.homepage{
		&-offer{
			&-frame{
				.frame{
					&-item{
						&-icon{
							height: 40px;
							font-size: 18px;
							padding: 11px 0;
							width: 40px;
						}
						&-text{
							font-size: 17px;
						}
					}
				}
			}
		}
		&-novelties{
			&-frame{
				.frame{
					&-title{
						font-size: 26px;
					}
					&-text{
						font-size: 16px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1199px){
	.header{
		&-menu{
			&-desktop{
				> li{
					margin-left: 11px;
					> a{
						font-size: 14px;
						margin: 40px 0px;
					}
					ul{
					  top: calc(100% - 34px);
						li{
							a{
								font-size: 13px;
								height: 33px;
								padding: 7px 9px;
							}
						}
					}
					&:last-child{
						> a{
							font-size: 13px;
							height: 35px;
							line-height: 35px;
							padding-right: 10px;
							&:before{
								height: 35px;
								margin-right: 10px;
								width: 35px;
							}
						}
					}
					&:hover{
						ul{
							li{
								height: 33px;
							}
						}
					}
				}
			}
		}
	}
	
	.footer{
		&-menu{
			li{
				white-space: nowrap;
			}
		}
	}
	
	.homepage{
		&-slider{
			.item{
				height: 450px;
			}
		}
		&-offer{
			&-box{
				.caption{
					font-size: 18px;
				}
			}
			&-frame{
				.frame{
					&-item{
						&-icon{
							vertical-align: middle;
						}
						&-text{
							max-width: calc(100% - 90px);
							text-align: left;
							vertical-align: middle;
						}
					}
				}
			}
		}
		&-novelties{
			&-frame, &-frame.frame-right{
				text-align: center;
				.frame{
					&-title, &-text{
						margin-left: 25px;
						margin-right: 25px;
						text-align: center;
					}
					&-button{
						left: 0;
						margin-bottom: 20px;
						margin-left: 0;
						margin-top: 10px;
						position: relative;
						top: 0;
						transform: none;
					}
				}
			}
		}
	}
	
	.subpage{
		&-offer{
			&-box{
				.imagebox{
					&-ratio{
						padding-bottom: 65%;
					}
				}
			}
		}
	}
	
	.contact{
		&-map{
			height: 300px;
		}
	}
}
@media screen and (max-width: 1024px){

}
@media screen and (max-width: 991px){
	.hamburger{
		display: inline-block;
	}
	
	.aside{
		&.active{
			transform: translateX(0px);
			transition: transform .4s linear .1s;
		}
	}
	
	.footer{
		&-column{
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	
	.homepage{
		&-slider{
			.item{
				&-background{
					opacity: 0.7;
				}
			}
		}
		&-offer{
			&-frame{
				.frame{
					&-item{
						&-icon{
							vertical-align: top;
						}
					}
				}
			}
		}
		&-novelties{
			&-tools{
				img{
					max-width: 95%;
				}
			}
		}
	}
	
	.subpage{
		&-offer{
			&-box{
				margin-bottom: 35px;
			}
		}
	}
}
@media screen and (max-width: 767px){
	.homepage{
		&-slider{
			.item{
				&-content{
					padding: 0 50px;
				}
				&-text{
					padding: 10px 10px;
					p, ul{
						//margin-bottom: 10px;
					}
				}
				&-button{
					//margin-left: 45px;
				}
			}
			&-controls{
				.owl-prev, .owl-next{
					height: 30px;
				}
				i{
					font-size: 12px;
					padding: 9px 11.5px;
				}
			}
		}
		
		&-offer{
			&-frame{
				.frame{
					&-title{
						padding: 0 45px;
					}
					&-item{
						text-align: left;
						&-icon{
							vertical-align: middle;
						}
					}
				}
			}
		}
		
		&-novelties{
			&-row{
				margin-top: 0;
			}
			&-tools{
				left: auto;
				position: relative;
				text-align: center;
				top: auto;
				transform: none;
			}
			&-frame{
				margin-bottom: 20px;
			}
		}
		
		&-logos{
			&-carousel{
				padding: 0 45px;
			}
			&-controls{
				.owl-prev, .owl-next{
					height: 30px;
				}
				i{
					font-size: 12px;
					padding: 9px 11.5px;
				}
			}
		}
	}
}
@media screen and (max-width: 640px){

}
@media screen and (max-width: 567px){
	.footer{
		&-bottom{
			&-text{
				margin: 10px 0;
				text-align: center;
				width: 100%;
			}
		}
	}
}
@media screen and (max-width: 480px){
	.col-ms-pull-0{
		right: 0;
	}
	.col-ms-12{
		margin-left: 0;
	}
	
	.global{
		&-title{
			font-size: 26px;
		}
	}
	
	.footer{
		&-menu{
			li{
				white-space: normal;
			}
		}
	}
	
	.homepage{
		&-offer{
			&-box{
				.imagebox{
					&-ratio{
						padding-bottom: 40%;
					}
				}
			} 
			&-frame{
				.frame{
					&-title{
						font-size: 26px;
					}
					&-item{
						margin-left: 50%;
						text-align: left;
						transform: translateX(-50%);
						width: 90%;
					}
				}
			}
		}
		
		&-novelties{
			&-box{
				.imagebox{
					&-ratio{
						padding-bottom: 45%;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 360px){
	
}

//ZAKRES 640PX - 767PX DO DEVELOPERS TOOLS W CHROME

@media screen and (max-width: 767px) and (min-width: 640px){
	.pod-chrome{
		display: none;
	}
}

@media screen and (max-width: 639px) and (min-width: 568px){
	.pod-chrome{
		display: none;
	}
}

@media screen and (max-width: 567px) and (min-width: 480px){
	.pod-chrome{
		display: none;
	}
}